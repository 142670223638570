<template>
    <div class="rack-picking">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Rack picking - Almacenamiento manual',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabricantes de estantería para almacenamiento manual' },
			{ name: 'keywords', content:'estantería pesada, estanteria metálica, estanteria para almacenamiento, rack manual, picking manual, rack picking' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Rack picking - Almacenamiento manual'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabricantes de estantería para almacenamiento manual'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Rack picking - Almacenamiento manual'},
			{name: 'twitter:description', content: 'Fabricantes de estantería para almacenamiento manual' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Rack picking - Almacenamiento manual'},
			{itemprop: 'description', content: 'Fabricantes de estantería para almacenamiento manual' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Rack picking - Almacenamiento manual',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'Rack picking 1',
                        description: `
                            <p>
                                Es un método óptimo para el almacenamiento manual de artículos o
                                productos de tamaños pequeños y medios, así mismo está proyectado para generar un
                                riguroso control de los inventarios, posibilitar el flujo de stocks o reservas, agilizar el
                                tiempo y trabajo en el manejo de la mercancía.
                            </p>
                            <p>
                                La diversidad de elementos, piezas y configuraciones de la estructura, proporcionan la
                                adaptación de la misma a distintos tipos de mercancía.
                            </p>
                            <ul class="pl-3">
                                <li class="mb-2">Proporciona la adaptación de distintos tipos de mercancía.</li>
                                <li class="mb-2">Esta clase de estantería se acomoda con facilidad al lugar disponible.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/rack-picking/rack_piking_4.jpg',
                        slides: [
                            'estanterias-metalicas/rack-picking/rack_piking_1.jpg',
                            'estanterias-metalicas/rack-picking/rack_piking_2.jpg',
                            'estanterias-metalicas/rack-picking/rack_piking_3.jpg',
                            'estanterias-metalicas/rack-picking/rack_piking_4.jpg',
                            'estanterias-metalicas/rack-picking/rack_piking_5.jpg',
                            'estanterias-metalicas/rack-picking/rack_piking_6.jpg',
                            'estanterias-metalicas/rack-picking/rack_piking_7.jpg',
                            'estanterias-metalicas/rack-picking/rack_piking_8.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    } 
                ],
            }
        }
    }
}
</script>